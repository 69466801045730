const Member = (member_name, title, year, description, image_url, links) => {
   return {
      name: member_name,
      title: title,
      class: year,
      description: description,
      image: image_url,
      links: links
   }
}

const Icon = (link, icon, icon_hover) => {
   return {
      link,
      icon,
      icon_hover
   }
}

const LinkedIn = (link) => {
   return Icon(link, "linkedin", "linkedin_hover")
}

const Website = (link) => {
   return Icon(link, "link", "link_hover")
}

// retired members !
/*
   Member("Dylan", "President", "2023", "Dylan is a fourth year Mechanical Engineering major. He loves to get boba with no add-ons. Dylan is currently trying to collect keyboard layouts like they are infinity stones.", require("../assets/portraits/dylan.webp"), [LinkedIn("https://www.linkedin.com/in/dylan-nguyen-226500208/")]),
   Member("Rayi", "Vice President", "2023", "Rayi is a fourth year Computer Engineer with too many hobbies and not enough time. He enjoy playing the same games over and over and going very fast on small vehicles. Misses Costco combination pizza dearly.", require("../assets/portraits/rayi.webp"), [LinkedIn("https://www.linkedin.com/in/rayi-l-8240761b0/")]),
   Member("Kevin", "Project Manager", "2023", "Kevin is a fourth year History major who is currently figuring out how to make the most silent linear board as possible, lest his roommates hear him typing up an essay at 2 AM. He's praying for the day he can afford and move into a single, so he can begin using blues.", require("../assets/portraits/kevin.webp"), [LinkedIn("https://www.linkedin.com/in/kevin-liu-b38807175/")]),
   Member("Jun", "Design Lead", "2024", "Jun is presently a third-year Criminology major who has major separation anxiety from her cat. She enjoys design, streamlining systems and eating copious amounts of mango pudding! When not in passionate rambles about the newest design trends, Jun is trying to justify the need for 3 separate keyboards.", require("../assets/portraits/jun.webp"), [LinkedIn("https://www.linkedin.com/in/huangnuj/")]),
   Member("Nam", "President", "2024", "Nam is a fourth year Materials Science and Engineering major who gleans major pleasure from looking at keyboards on the internet. Unfortunately he does not have money for said keyboards so his other interests include working on his bicycle and attempting to cook.", require("../assets/portraits/nam.webp"), []),
   Member("Alice", "Vice President", "2024", "Alice is a fourth year Software Engineering major. She likes doing her nails, playing Valorant, and going to Daiso to get stuff she does not need. She also enjoys buying cheaper keyboards rather than just saving up to build a higher quality one, but the more the better!", require("../assets/portraits/alice.webp"), []),
   Member("Aaron", "Treasurer", "2024", "Aaron is a fourth year Business Economics major. He grinds his life away on Valorant and copes through Dr. Pepper and spreadsheets. Aaron will refuse to spend money on anything other than food and tech as nothing else is useful.", require("../assets/portraits/aaron.webp"), [LinkedIn("https://www.linkedin.com/in/aaron-siegler-1a466a166")]),
   Member("Anna", "Project Manager", "2025", "Anna is a third year double major in Chemistry and Biological Sciences. Her past-times include playing with her dog, unnecessarily spending on keyboards and keyboard supplies, and cycling through complaining about chemistry lab, being broke, and needing boba. If you buy her a boba, she may love you more than her dog and keyboards combined, no promises ;)", require("../assets/portraits/anna.webp"), [LinkedIn("https://www.linkedin.com/in/annanguyen03/")]),
   Member("Karen", "Project Manager", "2025", "Karen is a fourth year International Studies major who likes to spend her time scrolling away reading manhwas and searching for her new fixation to fall down its rabbit hole. Throughout her journey in the keeb world, she has been gravitating towards smaller and smaller keyboards: from 75% to 65% to now searching for the perfect 40% to add to her collection.", require("../assets/portraits/karen.webp"), [LinkedIn("https://www.linkedin.com/in/kayang213/")]),
   Member("Kayla", "Design Lead", "2026", "Kayla is an undeclared second year who loves design and esports. When she isn’t losing her ranked games, you can find her practicing retail therapy or getting late noms with friends. She also is an avid fan of creamy and poppy clacks and fiends for new 65% boards.", require("../assets/portraits/kayla.webp"), [LinkedIn("https://www.linkedin.com/in/kaywng/")]),
*/


const members = [
   Member("Lillian", "President", "2027", "Lillian is a second year Mechanical Engineering major who loves photography, sunsets, and all things aesthetic. You can probably find her at her desk either napping, typing, or looking at pictures of her cat.", "portraits/lillian.webp", [Website("https://lillian.productions/")]),
   Member("Misaki", "Vice President", "2027", "Misaki is a second year CSE major who plays copious amounts of League. When she's not playing League, she's probably getting crafty: sewing a plush, crocheting a poorly structured duck, and things of the like.", "portraits/misaki.webp", [Website("https://misakino.carrd.co/")]),
   Member("Anthony", "Public Chair & PM", "2025", "Anthony (or Tony) is a fourth year Biological Science and Anthropology double major. During his free time he likes to spend it (and all his money) building keyboards and legos. His favorite beverage is a taro milk tea.", "portraits/anthony.webp", []), 
   Member("Kevin", "Technical Design", "2025", "Kevin is a fourth year Computer Science and Engineering major. He spends too much time designing keyboards and tinkering with circuit boards.", "portraits/kevinthai.webp", []),
   Member("Vietbao", "Webmaster", "2025", "Vietbao is a first year Masters Biomedical Engineering major who happens to dabble in web development in his free time, with experience in Node.js and Vue. He also enjoys photography, circuits, and 3D printing.", "portraits/vietbao.webp", [LinkedIn("https://www.linkedin.com/in/vietbao-tran-9584721a6/")]),
   Member("Benjamin", "Webmaster", "2025", "Benjamin is a fourth year Computer Science major who is always messing around with keyboards instead of studying. When he's not wasting his time with that, he can be found sleeping or working on silly little projects.", "portraits/benji.webp", [Website("https://benjiwong.com")]),
]

module.exports =  { members }
