<template>
   <div class="event-info">
      <div class="info">
         <img alt="quit" @click="quit()" class="quit" src="../../assets/icons/quit.webp" />
         <h2>{{ event.name }}</h2>
         <p class="date">{{ event.date }}</p>
         <CustomButton v-if="event.button_text != ''" :class="event.disabled ? 'disabled' : ''" :content="event.button_text" :url="event.rsvp_url" />
         <p class="description">{{ event.long_description }}</p>
         <div class="faq">
            <div 
               class="faq-entry"
               v-for="q in event.faq"
               :key="q.question"
            >
               <p><strong>{{ q.question }}</strong></p>
               <p v-if="q.type === 'text'">{{ q.answer }}</p>
               <ul v-else-if="q.type.includes('list')">
                  <li v-for="a in q.answer" :key="a">{{ a }}</li>
               </ul>
            </div>
         </div>
      </div>
      <div class="background" @click="quit()"></div>
   </div>
</template>

<script setup>

import CustomButton from "../../components/CustomButton.vue"

import { defineProps, defineEmits } from "vue"

defineProps([
   "event"
])

const emit = defineEmits([
   "quit"
])

const quit = () => {
   emit("quit")
}

</script>

<style scoped>
@media (max-width: 750px) {
   .info {
      padding: 36px !important;
      width: 75% !important;
   }
}

.event-info {
   position: fixed;
   left: 0;
   top: 0;
   margin: 0;

   z-index: 2;
}

.background {
   position: fixed;

   left: 0;
   top: 0;

   width: 100vw;
   height: 100vh;
   margin: 0;
   
   background-color: var(--color-image-overlay);
}

.info {
   z-index: 2;
   position: fixed;

   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);

   max-width: 700px;
   width: 65%;
   height: fit-content;
   max-height: 450px;
   padding: 48px;

   overflow-y: scroll;

   background-color: var(--color-background);
   color: var(--color-primary);
   border-radius: 32px;
}

.quit {
   position: absolute;
   right: 24px;
   top: 24px;

   cursor: pointer;

   filter: invert(var(--invert));
}

.quit:hover {
   opacity: 0.5;
}

p {
   margin-bottom: 10px;
   font-size: 16px !important;
}

h2 {
   text-transform: none;
}

.faq {
   padding-top: 20px;
}

.faq-entry p {
   white-space: pre-wrap;
}

.button {
   margin-bottom: 15px;
   margin-top: 15px;
   font-size: 18px;
}

ul {
   padding-left: 25px;
}

li {
   padding-bottom: 6px;
}

</style>
