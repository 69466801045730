<template>
   <div class="page">
      <ContentBox class="top">
         <Header />
         <h1>Upcoming Events</h1>
         <p>Our events are open to everyone who wishes to participate in our club.</p>
         <div v-if="upcoming_events.length != 0">
            <div class="upcoming">
               <UpcomingEvent
                  v-for="event in upcoming_events"
                  :key="event"
                  :event="event"
                  :tag="upcoming_events.indexOf(event)"
                  @open="select_event"
               />
            </div>
         </div>
         <div class="upcoming" v-else>
            <p>No events planned.</p>
         </div>
      </ContentBox>
      <ContentBox class="bottom">
         <h1>Past Events</h1>
         <div class="past">
            <PastEvent
               v-for="event in past_events"
               :key="event"
               :event="event"
            />
         </div>
      </ContentBox>
      <EventInfo 
         :event="upcoming_events[selected_event]"
         v-if="selected_event != null"
         @quit="select_event(null)"
      />
   </div>
</template>

<script setup>
import Header from "../../components/Header.vue"
import ContentBox from "../../components/ContentBox.vue"
import { upcoming_events, past_events } from "../../data/data"
import PastEvent from "./PastEvent.vue"
import UpcomingEvent from "./UpcomingEvent.vue"
import EventInfo from "./EventInfo.vue"
import { ref } from "vue"

const selected_event = ref(null)

const select_event = (e) => {
   selected_event.value = e;
}

</script>

<style scoped>
@media (max-width: 750px) {
   .upcoming {
      padding-bottom: 48px !important;
   }

   .past {
      padding-bottom: 48px !important;
      margin-bottom: 20px;
   }

   .top h1 {
      margin-top: 0px !important;
   }


   .bottom h1 {
      padding-top: 24px !important;
      margin-bottom: 24px !important;
   }

   .upcoming,
   .past {
      gap: 20px !important;
      padding-bottom: 5vw !important;
   }

}

p {
   color: var(--color-primary);
}

.top h1 {
   margin-top: 180px;
}

.top p {
   font-size: 20px;
   font-style: italic;
   max-width: 500px;
}

.bottom h1 {
   padding-top: 64px;
   margin-bottom: 36px;
}

.upcoming {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 40px;

   padding-top: 36px;
   padding-bottom: 84px;
}

.past {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 24px;
   
   padding-bottom: 84px;
}
</style>
