<template>
   <Suspense>
      <div class="page">
         <MemberBlurb
            :member="selected_member ? selected_member : null"
            v-if="selected_member"
            @quit="select_member(null)"
         />
         <ContentBox class="image-box">
            <Header />
            <h1>About Our Club</h1>
            <p>Meet our board members and check out our sponsors!</p>
         </ContentBox>
         <div class="board-box">
            <h1>Board Members</h1>
            <div class="board" v-if="members.length != 0">
               <BoardMember
                  v-for="member in members"
                  :key="member.name"
                  :member="member"
                  @click="select_member(member)"
               />
            </div>
            <div class="loading" v-else>
               <!-- Loading... -->
            </div>
         </div>
         <SponsorBox
            class=""
            description="We’ve partnered with local keyboard businesses to bring you member-exclusive discounts! Access them through our Discord."
         />
      </div>
   </Suspense>
</template>

<script setup>

import { ref, } from "vue"

import ContentBox from "../../components/ContentBox.vue"
import Header from "../../components/Header.vue"
import BoardMember from "./BoardMember.vue"
import MemberBlurb from "./MemberBlurb.vue"
//import Button from "../../components/Button.vue"
import { members } from "../../data/data.js"
import SponsorBox from "../../components/SponsorBox/SponsorBox.vue"

// currently selected member. show a blurb when selected, 
// otherwise is null !
const selected_member = ref(null)

const select_member = (member) => {
   selected_member.value = member
}

</script>

<style scoped>
@media (max-width: 750px) {
   .board-box {
      padding: 0 5% 0 5% !important;
   }

   .board {
      justify-content: space-between;
      gap: 10px !important;
   }

   .image-box h1 {
      padding-top: 0 !important;
   }
}

.image-box::before {
   background-image: url("../../assets/page-images/about.webp");
}

.image-box h1 {
   padding-top: 176px;
}

.image-box p {
   color: var(--color-white);
}

.board-box {
   max-width: 1144px;
   padding: 0 10% 0 10%;
   margin: auto;
}

.board-box h1 {
   margin: 64px 0 36px 0;
}

.board {
   display: flex;
   flex-wrap: wrap;
   gap: 36px;
   text-align: center;
}

</style>
