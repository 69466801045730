<template>
   <div class="darkmode-toggle" ref="header" @click="() => emit('toggled')"> 
      <div class="darkmode-box">
         <img class="dropdown" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHElEQVR4nO3SMSiFYRSA4QcRiZSUUpSyKIvBYDKYLBYWk81iNNnvZLTYTBYWi8lgMhgsyqIUpZSUSES4Ur+SIpf/3vvdnKfOeOq8fR8hhBBCCCGEEHJXQDHxKfw0ZimBY4tfzHIpr1KHlQSOLn6aVdSX+sXeFtYSOL6YzQYa/NLb4noCEZto9EdN2KpixDaa5aQFO1WI2EWrnLVjr4IRe2hTJh3Yr0DEATqVWRcOyxhxhG4V0oPjMkScok+F9eIkx4gz9KuSAZznEHGBQVU2hMs/RFxhWCJGcPOLiOtsNymjuC0h4g5jEjWO+x9EPGBC4ibx+E3EE6bViKns4M8Rz5hRY2azw98jXjCnRs1/CFlQ4xazCSGEEEL4b14BnfLMQvEtlakAAAAASUVORK5CYII=">
         <img :class="props.enabled ? 'darkmode-status sun' : 'darkmode-status sun hidden'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABO0lEQVR4nN2WzUoDMRDHfxexe7cBP56qtA9k2/fQS99DW0U81Ip9ka4Kfhy6EpiFEGGapFMW/cMcNtmZXzI7mwz8QT0CD12AG7H/AT4HKgPwEXCWCvUvfgJrwCnvLYC5Mu99X4AP4DQF3BOHJgGuQdcSw8c6LnHUdqVlo3jhDrgDZtHYFFgB72JPwCQCzMS3JFu/NAJeg6KKzc8NMdYI2CrQ1raWcLdjp7HVQN8CPM2Atja2AD8XgH3BZZV/I+afW+WkOSy0lNjEk/M9wXVibFWrQ6Za06QAfEkHv9MGOMFIw4wDZGAFDeH1jp0OrFJ8D1wFY305HJbAm9hSvmmY3uvSS8IF1+JNwaJvS67FKoBaNQJVar/1LQ5uj/a2bX2+cvquC2mBNLVFpaknsUyVAj6Imq7Ai8JGsFv9AE0E4K4ek5vcAAAAAElFTkSuQmCC" />
         <img :class="props.enabled ? 'darkmode-status moon hidden' : 'darkmode-status moon'" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVR4nO3VTytEURgH4CeUEgtLhuzkY1iaBQvJF2CF1BSfwkrsKCv2UnwUWVj4t5ZRFmJGp46SzMy9457bLOat3+7ennvf3nNe+tWDNYnxMsEKTnGLobLQVdTRxF5ZaA2NiIYslPWnjR9oM7Y8aU3g+RcaMpwaPvsDDRlLfWTeW8CzKeHdFmjy4bpsAx+khO/awPcYTAW/tYFD1lLB9Q7wY6rpvukAN+McFN7yqwxwyD4GioS3MsIh50W2fRofOfAnrBfV+uMc8HcecIgq5jCKEcxgEfNZ4EqG6c6Tl7h4MlU1Z8tbJazWlbwt38bnP9Dw7oYua6nLtod9vqyAVXmUsfXhmZN4OgqrKWzGm+sar/FuD8vjAjvxI/vVW/UFABDWXHcFkVQAAAAASUVORK5CYII=" />
      </div>
   </div>
</template>

<script setup>

import { defineEmits, defineProps } from "vue"

const emit = defineEmits(["toggled"]);
const props = defineProps(["enabled"]);

</script>

<style scoped>
.darkmode-status {
   position: absolute;
}

.sun, .moon {
   transition: 0.5s transform cubic-bezier(0,.56,.16,1), 0.5s opacity cubic-bezier(0,.2,.16,1);
   opacity: 1;
}

.sun {
   transform: scale(100%) translate(0px, 0px);
}

.moon {
   transform: scale(100%) translate(0px, 0px);
}

.sun.hidden {
   transform: scale(0%) translate(25px, -25px);
   opacity: 0;
}

.moon.hidden {
   transform: scale(0%) translate(-25px, 25px);
   opacity: 0;
}

.dropdown {
   display: none;
}

.darkmode-toggle img {
   width: 24px;
   filter: invert(1);
}

@media (max-width: 750px) {
   .darkmode-toggle {
      transform: translateX(-50%);
   }
   
   .darkmode-status {
      left: -12px;
   }
}

/* unused hiding toggle 
@media screen and (min-width: 750px) {
.darkmode-toggle {
   z-index: 3;
   padding: 50px;
   padding-bottom: 65px;
   position: fixed;
   top: -77px;
   right: 50%;

   transition: 0.5s top cubic-bezier(0,.56,.16,1);
}

.dropdown {
   width: 18px;
   transform: scale(100%) rotate(-45deg);
   position: absolute;
   top: 20px;
   left: 20px;

   transition: 0.5s transform cubic-bezier(0,.56,.16,1);
}

.darkmode-box {
   background-color: var(--color-secondary);
   border-radius: 12px;
   padding: 10px;
   user-select: none;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;

   transform: rotate(45deg);

   transition: 0.5s border-radius cubic-bezier(0,.56,.16,1);
}

.darkmode-toggle:hover {
   top: -10px;
}

.darkmode-toggle:hover .darkmode-box {
   border-radius: 24px;
}

.darkmode-toggle:hover .darkmode-status {
   transform: scale(100%) rotate(-45deg);
}  

.darkmode-toggle:hover .dropdown {
   transform: scale(0) rotate(-45deg);
}

.darkmode-status {
   transform: scale(0) rotate(-45deg);

   transition: 0.5s transform cubic-bezier(0,.56,.16,1);
}

.darkmode-toggle img {
   width: 24px;
   filter: invert(calc(-1 * (var(--invert) - 1)));
}

}

@media (max-width: 750px)
{
   
}
*/
</style>
