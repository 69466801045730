const Sponsor = (icon, website_url) => {
   return {
      icon: icon,
      url: website_url
   }
}

const sponsors = [
   Sponsor("sponsor-logos/keyspensory.webp", "https://keyspensory.store/"),
   Sponsor("sponsor-logos/customkeysco.webp", "https://www.customkeysco.com/"),
   Sponsor("sponsor-logos/divinikey.webp", "https://divinikey.com/"),
   Sponsor("sponsor-logos/keebhut.webp", "https://keebhut.com/"),
]

module.exports = { sponsors }
