const UpcomingEvent = (name, type, description, when, where, map_url, gcal_url, rsvp_url, button_text, long_description = "", faq = [], disabled = true) => {
   return {
      name: name,
      type: type,
      description: description,
      date: when,
      location: where,
      map_url: map_url,
      gcal_url: gcal_url,
      rsvp_url: rsvp_url,
      button_text: button_text,
      long_description: long_description,
      faq: faq,
      disabled: disabled
   }
}

const PastEvent = (name, date, icon, photos, credit="", credit_url="") => {
   return {
      name: name,
      date: date,
      icon: icon,
      photos: photos,
      credit: credit,
      credit_url: credit_url
   }
}

const Question = (question, answer, type = "text") => {
   return {
      question,
      answer,
      type
   }
}

/*
Question
UpcomingEvent
const PhotoCredit = (author, link) => {
   return {
      author,
      link
   }
}
*/
const upcoming_events = [
   UpcomingEvent(
      "Snowy Switches",
      "Keeb Meet",
      "Participate in our Winter 2025 Keyboard meet! RSVP required.",
      "Sat. January 18, 3 - 6pm PDT",
      "Doheny Beach ABCD",
      "https://maps.app.goo.gl/y3QX6FCujfNewRps7",
      "",
      "https://forms.gle/tb7xat8FqUX9Gwzs8",
      "RSVP",
      "This event will be open to the public and free! We have many amazing vendors local to the OC/LA area attending and this is a great opportunity to meet them. There will be more frequent giveaways and typing contents to better accommodate everyone's schedules. Please RSVP!",
      [
         Question("Can I sell items?", "UCI does not allow the selling of items due to tax and insurance reasons. If you choose to sell items, neither Keebs Club @ UCI or UCI takes responsibility for transaction issues."),
         Question("Is parking free?", "Parking is not free and is $2/hr at the Student Center Parking Structure. We highly advise against parking at UTC and walking over because UTC will tow your vehicle. "),
         Question("How many keyboards can I bring?", "We do not have a board limit as we expect to have plenty of tables. You are more than welcome to showcase a few boards at a time and store the rest under the tables."),
         Question("What is the glove/touch policy?", "Gloves are optional and will be provided. Keyboards will indicate touch/no touch, gloves only/gloves not required, and pickup allowed/no picking up."),
         Question("Are Foods/Drinks allowed?", "No foods or drinks will be allowed in the building. Closed bottles can be carried, but please consume outside the building."),
         Question("Do I have to RSVP?", "The event will be open only to RSVP'd individuals due to safety capacity limits.")
      ],
      false 
   ),
 ]
const past_events = [
   PastEvent(
      "Fall '23 Keeb Meet",
      "11/05/2023",
      "events/23_fall_meet/1.webp",
      "https://photos.tapucosmo.dev/share/1pvDzMLCPhBTIViUHaxbEuBnJNLKJSCsKWBhZqY9h-pZrAO8AI1MafZ1Gc7NLsf38UU",
      "Vietbao Tran",
      "https://www.linkedin.com/in/vietbao-tran-9584721a6/"
   ),
   PastEvent(
      "Fall '22 Keeb Meet",
      "10/02/2022",
      "events/22_fall_meet/1.webp",
      "https://photos.google.com/share/AF1QipMGuOv1aZ_vhyvIeaVSKZTfmZ6JUXuw3ro_RZEVo0hKKQrGJqVygf4_8ZxxzYr1_Q/photo/AF1QipOy560zEf0e1aN7p45_a1KjpY5dxT5QqI0NWe3N?key=dlNFWU9Iak9QcTdNZmc0VUN0dTVxMXBKU0lmVFJn",
      "enomooshiki",
      "https://linktr.ee/enomooshiki"
   ),
   PastEvent(
      "Winter '22 Keeb Meet",
      "02/23/2022",
      "events/22_winter_meet/1.webp",
      "https://drive.google.com/drive/folders/1Pzl7BbU5xMH13aLFRZiiACkbESBHCS2z?usp=sharing",
      "Vietbao Tran",
      "https://www.linkedin.com/in/vietbao-tran-9584721a6/"
   ),
   PastEvent(
      "Fall '21 Keeb Meet",
      "11/09/2021",
      "events/21_fall_meet/1.webp",
      "https://drive.google.com/drive/folders/1ZCSKVLEUcLsrQTgU4N2Ol7Hdp_GU47QU?usp=sharing",
      "Vietbao Tran",
      "https://www.linkedin.com/in/vietbao-tran-9584721a6/"

   ),
]

module.exports = {
   upcoming_events,
   past_events
}
