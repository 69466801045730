<template>
   <div class="footer-box">
   <div class="footer">
      <div class="column start">
         <div>
            <a href="/">
               <img src="../assets/icons/smolbean.webp" />
            </a>
            <h2>Keebs Club At UCI</h2>
         </div>
         <div class="spacer"></div>
         <p>© Copyright Keebs Club at UCI 2024. All Rights reserved.</p>
      </div>
      <div class="column quicklinks">
         <h3>Quicklinks</h3>
         <a href="/">Home</a>
         <a href="/join">Join</a>
         <a href="/events">Events</a>
         <a href="/group-buys">Groupbuys</a>
         <a href="/about">About</a>
         <!--a href="https://claq.keebsclubuci.org">Claq</a-->
      </div>
      <div class="column contact">
         <h3>Contact</h3>
         <a href="mailto:keebsclub@uci.edu">keebsclub@uci.edu</a>
         <div class="spacer"></div>
         <div class="logo-box">
            <a href="https://discord.gg/xR5T7fyXTv" target="__blank">
               <img class="hover" alt="discord" src="../assets/links-logos/discord_hover.webp" />
               <img alt="discord" src="../assets/links-logos/discord.webp" />
            </a>
            <a href="https://www.instagram.com/keebsclubuci/" target="__blank">
               <img class="hover" alt="instagram" src="../assets/links-logos/instagram_hover.webp" />
               <img alt="instagram" src="../assets/links-logos/instagram.webp" />
            </a>
            <!--a href="404">
               <img class="hover" alt="reddit" src="../assets/links-logos/reddit_hover.webp" />
               <img alt="reddit" src="../assets/links-logos/reddit.webp" />
            </a-->
         </div>
      </div>
      <div class="column darkmode">
         <h3>Darkmode</h3>
         <DarkmodeToggle :enabled="props.darkmode_enabled" @toggled="() => emit('darkmode_toggled')" />
      </div>
      <div class="column end">
         <a href="/privacy-policy">Privacy Policy</a>
         <a href="/terms-of-service">Terms of Service</a>
         <div class="spacer"></div>
         <p class="profile">Designed by <span>Jun Huang</span></p>
         <a target="__blank" href="https://benjiwong.com" class="profile">Developed by <span>Benjamin Wong</span></a>
      </div>
   </div>
</div>
</template>

<script setup>
import DarkmodeToggle from "./DarkmodeToggle.vue"
import { defineProps, defineEmits } from "vue"

const props = defineProps(["darkmode_enabled"])
const emit = defineEmits(["darkmode_toggled"])

</script>

<style scoped>
@media (max-width: 750px) {

   .footer {
      flex-direction: column !important;
      height: auto !important;

      justify-content: center;
      align-items: flex-start;

      border-radius: 0 !important;

      margin: 0 !important;
      margin-top: 32px !important;
   }

   .quicklinks {
      gap: 6px;
   }

   .footer-box {
      padding: 0 !important;
   }

   .footer .column:first-child {
      padding-top: 36px !important;
   }

   .column {
      padding-top: 0 !important;
      padding-bottom: 24px !important;
   }

   .footer > * {
      margin: auto;
   }

   .footer a,
   .footer p,
   .footer h3 {
      margin: 0 !important;
      text-align: center !important;
   }

   .start {
      padding-left: 0 !important;
   }

   .end {
      padding-right: 0 !important;
      text-align: center !important;
      align-items: center !important;
   }

   .end a {
      margin-left: 0 !important;
   }

   .logo-box {
      justify-content: center;
      padding-top: 12px;
   }

   .end .spacer {
      height: 24px;
      flex: none !important;
   }

   .darkmode {
      display: flex;
      align-items: center;
   }

}

.footer-box {
   max-width: var(--page-width);
   margin: auto;

   padding: 0 2% 0 2%;
}

.footer {
   border-radius: 32px;
   background-color: var(--color-shaded);

   height: 225px;

   display: flex;
   flex-direction: row;
   margin: 32px 2.5% 32px 2.5%;

   gap: 10px;
}

h3 {
   font-size: 16px;
   color: var(--color-accent2);
   text-transform: none;
   font-family: 'Inter', sans-serif;
}

a, p {
   font-size: 16px;
   /* color: var(--color-background); */
   color: var(--color-white);
}

a, p, h3 {
   padding-bottom: 8px;
}

a {
   cursor: pointer;
   text-decoration: none;
}

a:not(.profile):hover,
a.profile:hover span {
   color: var(--color-accent);
}



.column {
   display: flex;
   flex-direction: column;

   padding-top: 36px;
   padding-bottom: 36px;
}

.start {
   flex: 2;
   padding-left: 7.5%;
}

.start > * {
   max-width: 235px;
}

.start > div {
   display: flex;
   flex-direction: row;
   align-items: center;
}

.start div img {
   width: 76px;
   cursor: pointer;
}

.start div img:hover {
   opacity: 0.8;
}

.start div h2 {
   color: var(--color-accent2);
   font-weight: bold;
   font-size: 20px;

   padding-left: 24px;
}

.end {
   flex: 2;
   padding-right: 7.5%;
}

.quicklinks {
   flex: 1;

   display: flex;
   flex-direction: columns;
}

.quicklinks a {
   max-width: 100px;
   padding-bottom: 0;
   flex: 1;
}


.contact {
   flex: 1;
}

.contact div {
   flex: 1;
}

.contact div img {
   width: 33px;
   border-radius: 0;
}

.contact a {
   padding-bottom: 0;
}

.contact .logo-box {
   display: flex;
   align-items: center;
   height: 33px;
   gap: 12px;
   flex: 0.5;
}

.end {
   flex: 2;
   padding-right: 7.5%;
   text-align: right;
   align-items: flex-end;
}

.end a {
   max-width: 225px;
}

.spacer {
   flex: 1;
}

.logo-box a {
   transition: opacity 100ms;
}

.logo-box .hover {
   position: absolute;
   opacity: 0;

   transition: 100ms opacity;
}

.logo-box a:hover .hover {
   opacity: 1;
}

a:hover {
   color: var(--color-white);
}
</style>
